.KesiConnect__mainWrapper___33qVM {
  font-family: sans-serif;
  padding: 20px 20px 20px 20px;
  word-wrap: break-word;
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
}

.KesiConnect__lowerWrapper___3tbvs {
  text-align: center;
}

.KesiConnect__btnConnectionCode___uDyTP {
  min-width: 150px;
  margin-bottom: 10px;
  font-size: 20px !important;
}

p {
  font-size: 14px;
  letter-spacing: 0;
}