.QuizTraining__mainContainer___2W2Ym {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.QuizTraining__buttonContainer___1XVk0 {
  width: 100%;
  text-align: center;
}

.QuizTraining__questionContainer___6ad9r {
  height: -webkit-calc(100% - 50px);
  height: -moz-calc(100% - 50px);
  height: calc(100% - 50px);
  max-height: -webkit-calc(100% - 50px);
  max-height: -moz-calc(100% - 50px);
  max-height: calc(100% - 50px);
}