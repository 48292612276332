:root {
  --color-titleBackgroundOverlay: rgba(255, 255, 255, 0.5);
  --color-tileFontColor: #525BA3;
  --color-tileFontWeight: 300;
  --color-tileBackgroundColor: #FFFFFF;

  --color-tileColors-1-hsl: hsl(233, 33%, 48%);
  --color-tileColors-1-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-2-hsl: hsl(233, 33%, 48%);
  --color-tileColors-2-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-3-hsl: hsl(233, 33%, 48%);
  --color-tileColors-3-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-4-hsl: hsl(233, 33%, 48%);
  --color-tileColors-4-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-5-hsl: hsl(233, 33%, 48%);
  --color-tileColors-5-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-6-hsl: hsl(233, 33%, 48%);
  --color-tileColors-6-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-7-hsl: hsl(233, 33%, 48%);
  --color-tileColors-7-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-8-hsl: hsl(233, 33%, 48%);
  --color-tileColors-8-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-9-hsl: hsl(233, 33%, 48%);
  --color-tileColors-9-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-10-hsl: hsl(233, 33%, 48%);
  --color-tileColors-10-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-11-hsl: hsl(233, 33%, 48%);
  --color-tileColors-11-hsl-dark: hsl(207, 10%, 36%);

  --color-navbarBackground: #FFFFFF;
  --color-navbarBorderBottom: 1px solid #9b9b9b;
  --color-navbarText: #525BA3;
  --color-menuBackground: #FFFFFF;
  --color-buttonBorder: #525BA3;
  --color-buttonBorderSecondary: #525BA3;
  --color-buttonCaption: #FFFFFF;
  --color-buttonCaptionSecondary: #FFFFFF;
  --color-buttonBackground: #525BA3;
  --color-buttonBackgroundLight: #c6defa;
  --color-buttonBorderLight: #8faed6;
  --color-buttonCaptionLight: #8faed6;
  --color-buttonBackgroundDark: #f4f4f4;
  --color-buttonBorderDark: #f4f4f4;
  --color-buttonCaptionDark: 	#525BA3;
  --color-lightText: 	#525BA3;
  --color-textfieldCaption: 	#525BA3;
  --color-tab: 	#525BA3;
  --color-tabBackground: #FFFFFF;
  --color-tableDivider: #D8D8D8;
  --color-dialogText: #6f6760;
  --color-dialogDivider: #a69b8d;
  --color-dialogBackground: #fbf9f7;
  --color-primaryColor1: #ff2337;
  --color-primaryColor2: #e32233;
  --color-navbarItemColor: #525BA3;
  --color-lectureHeaderText: #ffffff;

  --color-quizBackground: linear-gradient(#9cb9db, #c6defa);
  --color-quizMainColor: 	#525BA3;
  --color-quizBorderColor: 	#525BA3;
  --color-quizSecondColor: #c6defa;
  --color-quizThirdColor: #8faed6;
  --color-quizText: #FFFFFF;
  --color-quizTimer: #525BA3;
  --color-homeBackground: 	#525BA3;
  --color-highScoreUserColor: 	#525BA3;
  --color-highScoreOpponentColor: #FFFFFF;
  --color-menuItemHoverBackgroundColor: rgba(0, 0, 0, 0);
  --color-mainTextColor: #FFFFFF;
  --color-questionHeader: #bfb7ad;
  --color-questionHeaderText: #6a625a;
  --color-questionHeaderPointsText: #a69b8d;
  --color-questionTimer: #525BA3;
  --color-questionText: #FFFFFF;
  --color-answerDivider: #d8d8d8;
  --color-roundFeedbackHeaderText: #FFFFFF;
  --color-roundFeedbackDetailsText: #6f6760;
  --color-correct: #7ed321;
  --color-correctButNotChosen: #696158;
  --color-wrong: #d0011b;
  --color-opponentAnswer: #878787;

  --fontFamily: FSMe, sans-serif;
  --navBarMaxWidth: 45%;
  --navBarContainerLeftTop: 20px;
  --navBarHeight: 64;
  --navBarHeightPx: 64px;
  --navBarLogoMarginTop: 10;
  --navBarRightSideButtonsTop: 30px;
  --navBarLogoLeft: 9%;
  --navBarLogoHeight: 35px;
  --navBarLogoTop: 20px;
  --thresholdScreenWidth: 800px;
  --mainMenuWidth: 300px;
  --navBarMargin: 0 40px;
  --navBarMaxHeight: 60px;
  --navBarTextAlign: center;
  --navBarVerticalAlign: middle;
  --navBarFontSize: 16px;
  --navBarFontWeight: bold;
  --navbarTitleColor: #525BA3;
  --navBarPaddingTop: 14px;
  --navBarLineHeight: 19px;
  --burgerIconLeft: 10px;
  --navBarOverflow: hidden;
  --headerMinHeight: 200px;

  --spocInfoHeight: 40;
  --spocInfoHeightPx: 40px;

  --tiles-backgroundHeight: 120;
  --tiles-minTitleHeight: 121;
  --tiles-titleSize: 16;
  --tiles-iconSize: 80;
  --tiles-iconRight: 50%;
  --tiles-iconTransform: translateX(50%);
  --tiles-backgroundColor: rgba(255, 255, 255, 0.5);
  --tiles-blur: blur(10px);

  --app-maxWidth: 1024px;

  /* iOS Safari 11.2, Safari 11 */
  --safe-area-top-constant: calc(constant(safe-area-inset-top, 0px) / 2.0);
  /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
  --safe-area-top-env: calc(env(safe-area-inset-top, 0px) / 2.0);
  /* iOS Safari 11.2, Safari 11 */
  --safe-area-bottom-constant: calc(constant(safe-area-inset-bottom, 0px) / 1.4);
  /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
  --safe-area-bottom-env: calc(env(safe-area-inset-bottom, 0px) / 1.4);

  --htmlSafeAreaPaddingDivisor: 1.4;
}
