.DrupalLikertNugget__optionContainer___RLa_Z {
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.DrupalLikertNugget__optionContainerMobile___nSYwZ {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.DrupalLikertNugget__forcedDesktop___2_Wf5 {
  padding: 20px;
}

.DrupalLikertNugget__optionLine___3Tz9g {
  width: 80%;
  height: 5px;
  background-color: grey;
  position: absolute;
  left: 10%;
  top: 22px;
  z-index: 0;
}

.DrupalLikertNugget__optionQuestion___2utp6 {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.DrupalLikertNugget__optionQuestionMobile___3Zf0x {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-bottom: 16px;
}

.DrupalLikertNugget__sliderContainer___DQSJF {
  padding-top: 30px !important;
}

.DrupalLikertNugget__divider___29Xaq {
  color: grey;
  width: 80%;
  margin: 30px auto;
  height: 4px;
}

.DrupalLikertNugget__smallDivider___1Cldx {
  color: grey;
  width: 80%;
  margin: 10px 0;
  height: 4px;
}

.DrupalLikertNugget__resultContainer___3xpzB {
  vertical-align: bottom;
}

.DrupalLikertNugget__optionBoxContainer___2caP4 {
  position: relative;
}

.DrupalLikertNugget__singleOptionBoxContainer___3AwDb {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.DrupalLikertNugget__checkBoxRoot___3wFGm svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.DrupalLikertNugget__innerBox___3TtPF {
  display: inline-block;
}

.DrupalLikertNugget__leftBox___2Hr2N, .DrupalLikertNugget__rightBox___3S7eG {
  display: inline-block;
}

@media screen and (max-width: 1204px) {
  .DrupalLikertNugget__resultLabel___M9l8k {
    font-size: 16px !important;
    padding-top: 38px !important;
    max-width: 25%;
  }

  .DrupalLikertNugget__optionTitle___2sKGQ {
    font-size: 14px !important;
    padding-top: 5px;
  }
}
@media screen and (max-width: 420px) {
  .DrupalLikertNugget__resultLabel___M9l8k {
    font-size: 1em !important;
    max-width: 25%;
  }

  .DrupalLikertNugget__optionTitle___2sKGQ {
    font-size: 0.8em !important;
    padding-top: 5px;
  }
}
.DrupalLikertNugget__resetButon___3r8eP {
  color: #3d3d3d !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 20px !important;
}

.DrupalLikertNugget__MuiSlider-valueLabel___1APWR > span > span {
  color: inherit !important;
}

.DrupalLikertNugget__infoText___1xzUy {
  width: 100%;
  text-align: center;
  font-size: 1em !important;
  padding-top: 1.2em !important;
  color: grey;
}

.DrupalLikertNugget__buttonContainer___2uTg- {
  padding-top: 10px;
  width: 100%;
  position: relative;
}

.DrupalLikertNugget__nextButton___3vygO, .DrupalLikertNugget__backButton___3R7Tv {
  width: 40% !important;
  margin: 0 5% !important;
  display: inline-block;
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
}

.DrupalLikertNugget__singleNextButton___25mE_ {
  width: 50% !important;
  margin: 0 25% !important;
  display: inline-block;
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
}

.DrupalLikertNugget__nextButtonDisabled___2-Ad7, .DrupalLikertNugget__backButtonDisabled___3uis8 {
  width: 40% !important;
  margin: 0 5% !important;
  display: inline-block;
  background-color: grey !important;
  color: var(--color-buttonCaption) !important;
}

.DrupalLikertNugget__singleNextButtonDisabled___2iQKF {
  width: 50% !important;
  margin: 0 25% !important;
  display: inline-block;
  background-color: grey !important;
  color: var(--color-buttonCaption) !important;
}

.DrupalLikertNugget__hr___1yMCO {
  border-top: 3px solid #ccc;
}

.DrupalLikertNugget__box___2XCsy {
  width: 94%;
  height: 100%;
  padding: 5px 3%;
  will-change: background-color;
  -webkit-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  transition: background-color 200ms;
}

.DrupalLikertNugget__boxUnchecked___-QwQ3 {
  background-color: var(--color-checkBoxColor) !important;
}

.DrupalLikertNugget__boxChecked___ivqxL {
  background-color: var(--color-checkBoxCheckedColor) !important;
}

.DrupalLikertNugget__checkBoxLeft___3TPjZ {
  -webkit-border-radius: 3px 0 0 3px;
     -moz-border-radius: 3px 0 0 3px;
          border-radius: 3px 0 0 3px;
}

.DrupalLikertNugget__checkBoxRight___aT79z {
  -webkit-border-radius: 0 3px 3px 0;
     -moz-border-radius: 0 3px 3px 0;
          border-radius: 0 3px 3px 0;
}

.DrupalLikertNugget__topicText___13Emj {
  width: 100%;
  font-size: 30px;
  position: relative;
}
.DrupalLikertNugget__topicText___13Emj p {
  color: var(--basecolor-orange);
  font-size: 30px;
  text-align: center;
}

.DrupalLikertNugget__topicImage___2A2ym {
  width: auto;
  height: 300px;
}

.DrupalLikertNugget__postResultContainer___3r3nB {
  width: 90%;
  margin: 0 auto;
}

.DrupalLikertNugget__postResultTitle___1easM {
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
  color: var(--color-ergoRed);
  padding-left: 10px;
}

.DrupalLikertNugget__postResultColorContainer___2NdwU {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.DrupalLikertNugget__downloadContainer___cOmnc {
  position: relative;
  width: 100%;
  padding-top: 20px;
}

.DrupalLikertNugget__postResultColorIndicator___2O1DK {
  background-color: yellow;
  width: 15px;
  height: 15px;
  display: block;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  vertical-align: middle;
  margin: 2px;
}

.DrupalLikertNugget__postResultInfo___2zDkr {
  margin-bottom: 10px;
}

.DrupalLikertNugget__postResultValue___vV5KE {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
}

.DrupalLikertNugget__postResultNorm___1-Tbm {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}

.DrupalLikertNugget__ergoButton___CL2of {
  background-color: var(--color-ergoRed) !important;
  color: white !important;
}

.DrupalLikertNugget__posIcon___2Z-rz {
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  background-color: var(--color-ergo-iceblue5);
  color: white;
  width: 0.8em;
  height: 0.8em;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 !important;
  margin: 1px;
}

.DrupalLikertNugget__innerIcon___2eCcp {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.DrupalLikertNugget__negIcon___31Ycn {
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  background-color: var(--color-ergo-violet5);
  color: white;
  width: 0.8em;
  height: 0.8em;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 !important;
  margin: 1px;
}