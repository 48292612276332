.ErgoTopLevelView__shieldContainer___2qF8B {
  width: 100%;
  height: 28vh;
  position: relative;
  background-color: white;
  text-align: center;
}

.ErgoTopLevelView__imageContainer___z1eHp {
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
  will-change: opacity;
}

.ErgoTopLevelView__imageContainerNoTrans___3Jx5D {
  -webkit-transition: opacity 0ms;
  -o-transition: opacity 0ms;
  -moz-transition: opacity 0ms;
  transition: opacity 0ms;
  will-change: opacity;
}

.ErgoTopLevelView__targetShieldBackground___3LJJL {
  height: 100%;
  min-width: 100%;
  left: 0;
  z-index: 0;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.ErgoTopLevelView__targetShieldBackgroundTopOverlay___2F2_b {
  height: 29%;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 3;
  background: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, black, rgba(0, 0, 0, 0));
  background: -moz- oldlinear-gradient(top, black, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(top, black, rgba(0, 0, 0, 0));
  background: linear-gradient(to bottom, black, rgba(0, 0, 0, 0));
  opacity: 0.4;
}

.ErgoTopLevelView__targetShieldBackgroundBottomOverlay___3gjZl {
  height: 15%;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 3;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background: -moz- oldlinear-gradient(top, rgba(255, 255, 255, 0), white);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.ErgoTopLevelView__locationInfos___1E95t {
  display: block;
}

.ErgoTopLevelView__riskRingContainer___2H86k {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.ErgoTopLevelView__riskRingSwipe___3iO9z {
  position: relative;
  height: 100%;
  z-index: 5;
}

.ErgoTopLevelView__riskRingSwipe___3iO9z > div:first-child, .ErgoTopLevelView__riskRingSwipe___3iO9z > div:first-child > div {
  height: 100%;
}

.ErgoTopLevelView__riskRingBackground___2pKvG {
  opacity: 0.73;
  display: inline-block;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 100%;
  position: absolute;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
}

.ErgoTopLevelView__riskRing___LZu0o {
  display: inline-block;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 100%;
  position: relative;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErgoTopLevelView__countryInformation___16ufi, .ErgoTopLevelView__targetInformation___3rK_T {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
}

.ErgoTopLevelView__targetInformation___3rK_T {
  top: 45%;
}

.ErgoTopLevelView__countryInformationSmall___2nBbX, .ErgoTopLevelView__targetInformationSmall___cfF3- {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.ErgoTopLevelView__textAnimation___27rWN {
  -webkit-animation: ErgoTopLevelView__fadeIn___mjSki 1s;
     -moz-animation: ErgoTopLevelView__fadeIn___mjSki 1s;
       -o-animation: ErgoTopLevelView__fadeIn___mjSki 1s;
          animation: ErgoTopLevelView__fadeIn___mjSki 1s;
  -webkit-animation-delay: 500ms;
     -moz-animation-delay: 500ms;
       -o-animation-delay: 500ms;
          animation-delay: 500ms;
  -webkit-animation-fill-mode: forwards;
     -moz-animation-fill-mode: forwards;
       -o-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes ErgoTopLevelView__fadeIn___mjSki {
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ErgoTopLevelView__fadeIn___mjSki {
  100% {
    opacity: 1;
  }
}

@-o-keyframes ErgoTopLevelView__fadeIn___mjSki {
  100% {
    opacity: 1;
  }
}

@keyframes ErgoTopLevelView__fadeIn___mjSki {
  100% {
    opacity: 1;
  }
}
.ErgoTopLevelView__infoContainer___2HE-E {
  width: 100%;
  height: 100%;
}

.ErgoTopLevelView__regionType___Jkkcj, .ErgoTopLevelView__targetType___JDLPV {
  height: auto;
  position: relative;
  color: white;
  padding: 10px;
  font-size: 18px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  font-weight: bold;
}

.ErgoTopLevelView__targetType___JDLPV {
  min-height: 48px;
}

.ErgoTopLevelView__dotContainer___17BfA {
  width: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  position: absolute;
  top: 10px;
  z-index: 6;
}

.ErgoTopLevelView__activeDot___1IblE {
  width: 6px;
  height: 6px;
  background-color: var(--color-ergoDarkRed);
  display: inline-block;
  position: relative;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 5px;
}

.ErgoTopLevelView__dot___32ER9 {
  width: 6px;
  height: 6px;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 5px;
  opacity: 0.5;
  background-color: #ffffff;
}

.ErgoTopLevelView__locationRiskInfo___c8vzO {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 20%;
}

.ErgoTopLevelView__locationMarker___1QNBd {
  width: 60%;
  height: auto;
}

.ErgoTopLevelView__locationMarkerOff___1HkE_ {
  width: 80%;
  height: auto;
}

.ErgoTopLevelView__locationRisk___1zgos {
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  padding-bottom: 30%;
  width: 100%;
  margin-top: -10%;
}

.ErgoTopLevelView__secureProfile___qS9Ta {
  color: black;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ErgoTopLevelView__addIcon___6Cs8W {
  width: 30%;
  height: auto;
  color: white;
}

.ErgoTopLevelView__arrowContainer___3Ge-o {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  will-change: transform, opacity;
}

.ErgoTopLevelView__arrows___2hiiQ {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: 5;
}

.ErgoTopLevelView__arrow___1fL9D {
  width: 20px;
  height: auto;
  margin: 5px 0;
}

.ErgoTopLevelView__arrowContainerLeft___3pXfN {
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 30px;
  background-color: var(--color-ergoDarkRed);
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
}

.ErgoTopLevelView__arrowContainerRight___GHC18 {
  position: absolute;
  right: 0;
  top: 0px;
  width: 20px;
  height: 30px;
  background-color: var(--color-ergoDarkRed);
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
}

.ErgoTopLevelView__imageText___9GnKi {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;
  z-index: 1;
  max-width: 90%;
  text-align: left;
  font-weight: bold;
}

.ErgoTopLevelView__circleAnimation___NqfZ2 {
  -webkit-animation: ErgoTopLevelView__progress___1Hj7O 1s ease-out forwards;
     -moz-animation: ErgoTopLevelView__progress___1Hj7O 1s ease-out forwards;
       -o-animation: ErgoTopLevelView__progress___1Hj7O 1s ease-out forwards;
          animation: ErgoTopLevelView__progress___1Hj7O 1s ease-out forwards;
  -webkit-animation-delay: 500ms;
     -moz-animation-delay: 500ms;
       -o-animation-delay: 500ms;
          animation-delay: 500ms;
  opacity: 0;
}

@-webkit-keyframes ErgoTopLevelView__progress___1Hj7O {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ErgoTopLevelView__progress___1Hj7O {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes ErgoTopLevelView__progress___1Hj7O {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ErgoTopLevelView__progress___1Hj7O {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}
.ErgoTopLevelView__svg___1cJiw {
  -webkit-transform: rotate(-90deg);
     -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
       -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: transparent;
  z-index: 20;
}

.ErgoTopLevelView__itemCss___rciGq {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  will-change: opacity;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
}

.ErgoTopLevelView__targetDescription___18Sdc {
  font-family: var(--shield-fontFamily);
  font-weight: 600;
  font-size: 4vw;
  position: relative;
  text-align: left;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0 13% 0 5%;
}

.ErgoTopLevelView__openArrow___1UQxl {
  width: 15%;
  padding: 10px;
  height: auto;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ErgoTopLevelView__openArrowInline___15AK6 {
  height: 50px;
  padding: 10px;
  width: auto;
  position: relative;
}

.ErgoTopLevelView__select___2sQZp {
  width: 100% !important;
  margin-top: 10px !important;
}

.ErgoTopLevelView__targetContainer___2sxtq {
  width: 100%;
  height: auto;
  min-height: 25%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  overflow: hidden;
  padding: 5px 0;
}

.ErgoTopLevelView__notVisibleIcon___1gi7G {
  position: absolute;
  left: 30px;
  top: 10px;
  z-index: 20;
  width: 30px;
  height: auto;
}