.PauseHelper__button___2UXF9 {
  display: block;
  margin: 15px auto !important;
}

.PauseHelper__title___1G-mL {
  display: block;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}

.PauseHelper__subtitle___35x7M {
  display: block;
  padding: 10px;
  font-weight: bold;
}

.PauseHelper__container___1h2xF {
  width: 100%;
  height: 100%;
  background: white;
  padding: 30px;
  padding-top: -webkit-calc(30px + var(--navBarHeightPx));
  padding-top: -moz-calc(30px + var(--navBarHeightPx));
  padding-top: calc(30px + var(--navBarHeightPx));
  text-align: center;
}
@supports (padding-top: constant(safe-area-inset-top)) {
  .PauseHelper__container___1h2xF {
    padding-top: -webkit-calc(30px + var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: -moz-calc(30px + var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: calc(30px + var(--navBarHeightPx) + var(--safe-area-top-constant));
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  .PauseHelper__container___1h2xF {
    padding-top: -webkit-calc(30px + var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: -moz-calc(30px + var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: calc(30px + var(--navBarHeightPx) + var(--safe-area-top-env));
  }
}

.PauseHelper__select___1uFs4 {
  display: block;
  margin: 0 auto;
  width: 20%;
}

.PauseHelper__closeIcon___A0g48 {
  position: absolute;
  top: -webkit-calc(10px + var(--navBarHeightPx));
  top: -moz-calc(10px + var(--navBarHeightPx));
  top: calc(10px + var(--navBarHeightPx));
  right: 10px;
  width: 30px;
  height: 30px;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PauseHelper__closeIcon___A0g48 {
    top: -webkit-calc(10px + var(--navBarHeightPx) + var(--safe-area-top-constant));
    top: -moz-calc(10px + var(--navBarHeightPx) + var(--safe-area-top-constant));
    top: calc(10px + var(--navBarHeightPx) + var(--safe-area-top-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PauseHelper__closeIcon___A0g48 {
    top: -webkit-calc(10px + var(--navBarHeightPx) + var(--safe-area-top-env));
    top: -moz-calc(10px + var(--navBarHeightPx) + var(--safe-area-top-env));
    top: calc(10px + var(--navBarHeightPx) + var(--safe-area-top-env));
  }
}