.QuizTrainingStatistic__feedBackContainer___2XMeh {
  height: 30%;
  text-align: center;
  padding: 20px;
}

.QuizTrainingStatistic__pointsContainer___2tbzP {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
}

.QuizTrainingStatistic__feedbackPoints___3nBAF {
  position: relative;
  display: block;
  font-size: 60px;
  font-weight: bold;
  width: 50%;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizTrainingStatistic__feedbackPointsText___3ZO3N {
  position: relative;
  display: block;
  color: white;
  font-size: 20px;
  text-align: center;
  top: 10px;
}

.QuizTrainingStatistic__feedbackHeading___2SV2q {
  font-size: 25px;
}

.QuizTrainingStatistic__feedbackText___T30VW {
  font-size: 15px;
}

.QuizTrainingStatistic__expansionPanel___KcNr8 {
  background-color: transparent !important;
}

.QuizTrainingStatistic__listContainer___meXrb {
  max-height: 50%;
  width: 100%;
  overflow: auto;
  padding: 0 10px;
  background-color: white;
}

.QuizTrainingStatistic__listItem___1D9VI {
  border-bottom: 1px solid #e7e6e6;
}

.QuizTrainingStatistic__listItemText___m1LvU {
  position: relative;
  color: #000000;
  width: 33%;
  text-align: center;
}
.QuizTrainingStatistic__listItemText___m1LvU span {
  font-family: var(--fontFamily) !important;
  font-size: 1.4em;
}

.QuizTrainingStatistic__listItemImgContainer___1vfwO {
  height: 1em;
  width: 1em;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizTrainingStatistic__listItemImg___9ykyf {
  height: 0.8em !important;
  font-size: 0.8em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizTrainingStatistic__buttonContainer___2Yx3N {
  max-height: 10%;
  width: 100%;
  text-align: center;
  padding: 20px;
  position: relative;
}

.QuizTrainingStatistic__listIcon___1WQDn {
  width: 20%;
  display: inline-block;
}

.QuizTrainingStatistic__button___21NnV {
  width: 85%;
  max-width: 450px;
}